import { IPaymentType } from "./payments.types"
const programDetails: Array<IPaymentType> = [
    { id: "1000", name: 'Bachelor in Business Administration' },
    { id: "1001", name: 'Master of Business Administration' },
    { id: "1002", name: 'CIM UK' },
    { id: "1003", name: 'CMI UK' },
    { id: "1004", name: 'Doctor of Business Administration' },
    { id: "1005", name: 'Executive MSc in Digital Marketing' },
    { id: "1006", name: 'Executive MSc in Entrepreneurship and Innovation' },
    { id: "1007", name: 'Executive MSc in Strategic Marketing' },
    { id: "1008", name: 'Executive MSc in Finance' },
    { id: "1009", name: 'Executive MSc in Strategic Management' },
]
export default programDetails