import { Button, Col, Descriptions, Divider, Row } from "antd";
import React, { MutableRefObject, useRef } from "react";
import NotFound from "../common/NotFound";
import { IPaymentSignedState, IRoutesState, IPaymentUnsignedState } from "../common/Routes";
import TitleSection from "../common/TitleSection";
import { IPaymentType } from "./payments.types";
import paymentTypes from "./paymentTypes";
import programDetails from "./programDetails";
export interface PaymentConfirmationProps extends IRoutesState {}
interface IDisplayItemsObj {
	title: string;
	text: string;
}
const typeFilter = (selector: string, array: typeof paymentTypes | typeof programDetails) => {
	return array.filter(({ name, id }: IPaymentType) => id === selector);
};

const PaymentConfirmation: React.FC<PaymentConfirmationProps> = (props) => {
	const {
		signedState: {
			amount,

			reference_number,
		},
		unsignedState: {
			bill_to_email,
			bill_to_forename,
			bill_to_phone,
			bill_to_surname,
			student_id,
			program,
			payment_type,
		},
	} = props;
	const bill_to: {
		[key: string]: string | undefined;
		bill_to_email: string | undefined;
		bill_to_forename: string | undefined;
		bill_to_surname: string | undefined;
		bill_to_phone: string | undefined;
	} = {
		bill_to_email,
		bill_to_forename,
		bill_to_phone,
		bill_to_surname,
	};
	const displayItems: MutableRefObject<Array<IDisplayItemsObj>> = useRef([
		{
			text: reference_number as string,
			title: "Reference Number",
		},
		{
			text: bill_to_email as string,
			title: "Student's email",
		},
		{
			text: bill_to_forename as string,
			title: "Student's first name",
		},
		{
			text: bill_to_surname as string,
			title: "Student's last name",
		},
		{
			text: bill_to_phone as string,
			title: "Student's phone no.",
		},
		{
			text: student_id as string,
			title: "Student's ID",
		},
		{
			text: typeFilter(program as string, programDetails)[0].name as string,
			title: "Program",
		},
		{
			text: typeFilter(payment_type as string, paymentTypes)[0].name as string,
			title: "Payment For",
		},
		{
			text: amount as string,
			title: "Amount",
		},
	]);
	// console.log(displayItems);
	return (
		<>
			<TitleSection desc={"Please confirm your payment details."}></TitleSection>
			<Divider></Divider>
			<Row justify="center">
				{/* <Col xs={22} sm={19} md={16} lg={13} xl={10} xxl={8}> */}
				<Descriptions
					// title="Payment Information"
					bordered
					layout={window.screen.width > 500 ? "horizontal" : "vertical"}
					column={1}>
					{displayItems.current.map(({ text, title }: IDisplayItemsObj, index) => (
						<Descriptions.Item
							label={title}
							key={`${index}-${title}`}
							labelStyle={{ padding: 16, fontWeight: 500 }}
							contentStyle={{ padding: 16 }}>
							{text}
						</Descriptions.Item>
					))}
				</Descriptions>
				{/* </Col> */}
			</Row>
			<div>
				<form
					id="payment_confirmation"
					action={
						process.env.NODE_ENV === "development"
							? "https://testsecureacceptance.cybersource.com/pay"
							: "https://secureacceptance.cybersource.com/pay"
					}
					method="post">
					{Object.keys(props.signedState).map((value: string, index: number) => (
						<input type="hidden" id={value} name={value} value={props.signedState[value]} />
					))}
					{Object.keys(bill_to).map((value: string, index: number) => (
						<input type="hidden" id={value} name={value} value={bill_to[value]} />
					))}
					<input
						type="hidden"
						id="merchant_defined_data1"
						name="merchant_defined_data1"
						value={typeFilter(payment_type as string, paymentTypes)[0].name as string}
					/>
					<input
						type="hidden"
						id="merchant_defined_data2"
						name="merchant_defined_data2"
						value={typeFilter(program as string, programDetails)[0].name as string}
					/>{" "}
				</form>
				<br></br>
				<Row justify="center">
					<Button form="payment_confirmation" htmlType="submit" type="primary">
						Confirm
					</Button>
				</Row>
			</div>
		</>
	);
};

export default PaymentConfirmation;
