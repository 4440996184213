import { Alert, Button, Col, Divider, Form, Input, InputNumber, Row, Select, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import moduleName from "axios";
import connection from "../common/connection";
import { valueType } from "antd/lib/statistic/utils";
import programDetails from "./programDetails";
import paymentTypes from "./paymentTypes";
import { useHistory } from "react-router";
import TitleSection from "../common/TitleSection";
import { IRoutesState } from "../common/Routes";
import { IPaymentType } from "./payments.types";
const { Option } = Select;
interface PaymentFormProps {
	setState: any;
}
interface IConvertedData {
	amount: string;
	bill_to_email: string;
	bill_to_forename: string;
	bill_to_surname: string;
	bill_to_phone: string;
	signed_date_time: string;
	payment_type: string;
	program: string;
	student_id: string;
}
interface IInputData {
	amount: number;
	email: string;
	fname: string;
	lname: string;
	tel: string;
	payment_type: string;
	program: string;
	student_id: string;
}

const PaymentForm: React.FC<PaymentFormProps> = ({ setState }) => {
	const desc =
		"Welcome to the Cambridge College Payment Portal. Please enter your payment details below to get started!";
	const history = useHistory();
	const [submitStatus, setSubmitStatus] = useState(null as unknown as string);
	const [errorInfo, setErrorInfo] = useState("Error occurred! try again.");
	const onFinish = (values: IInputData) => {
		const signed_date_time = new Date().toISOString().slice(0, -5) + "Z";
		const convertedData: IConvertedData = {
			amount: values.amount.toString(),
			bill_to_email: values.email,
			bill_to_forename: values.fname,
			bill_to_phone: values.tel,
			bill_to_surname: values.lname,
			signed_date_time,
			payment_type: values.payment_type,
			program: values.program,
			student_id: values.student_id,
		};
		console.log(convertedData);
		(async () => {
			try {
				// setSubmitStatus("onSubmit");
				const resp = await connection.post("api/payments", convertedData);
				// console.log(resp, "x");

				if (resp.status === 200) {
					const stateObj: IRoutesState = {
						infoAdded: true,
						signedState: resp.data.DATA,
						unsignedState: {
							bill_to_email: convertedData.bill_to_email,
							bill_to_forename: convertedData.bill_to_forename,
							bill_to_surname: convertedData.bill_to_surname,
							bill_to_phone: convertedData.bill_to_phone,
							student_id: convertedData.student_id,
							payment_type: convertedData.payment_type,
							program: convertedData.program,
						},
					};
					setState({ ...stateObj }, () => history.push("/confirm"));
				}
			} catch (err) {
				console.log(err);
				setSubmitStatus("error");
				return setErrorInfo("Error occurred! try again.");
			}
		})();

		console.log("Success:", values);
	};

	const onFinishFailed = (errorInfo: any) => {
		console.log("Failed:", errorInfo);
	};
	return (
		<>
			<TitleSection desc={desc}></TitleSection>
			<Divider></Divider>
			<Row justify="center">
				<Col xs={22} sm={19} md={16} lg={13} xl={10} xxl={8}>
					<Form
						initialValues={
							process.env.NODE_ENV === "development"
								? {
										email: "vikumwijekoon97@gmail.com",
										fname: "vikum",
										lname: "wijekoon",
										tel: "+94771922135",
										student_id: "10000",
										program: "1001",
										payment_type: "0",
										amount: 100,
								  }
								: undefined
						}
						id="paymentForm"
						layout="vertical"
						name="user-data"
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}>
						<Form.Item
							label="Student's Email"
							name="email"
							rules={[
								{
									required: true,
									message: "Email is not a valid email!",
									type: "email",
								},
							]}>
							<Input />
						</Form.Item>
						<Form.Item
							label="Student's First Name"
							name="fname"
							rules={[{ required: true, message: "Please input your first name!" }]}>
							<Input />
						</Form.Item>
						<Form.Item
							label="Student's  Last Name"
							name="lname"
							rules={[{ required: true, message: "Please input your last name!" }]}>
							<Input />
						</Form.Item>
						<Form.Item
							label="Phone No."
							name="tel"
							rules={[
								{
									required: true,
									message: "Not a valid phone number!",
									pattern: /^(\+|[0-9])[1-9][0-9 \-\(\)\.]{7,}$/gm,
								},
							]}>
							<Input />
						</Form.Item>
						<Form.Item
							label="Student ID"
							name="student_id"
							rules={[
								{
									required: true,
									message: "Please input your Student ID Number!",
								},
							]}>
							<Input />
						</Form.Item>
						<Form.Item
							label="Program"
							name="program"
							rules={[
								{
									required: true,
									message: "Please select your program!",
								},
							]}>
							<Select
								showSearch
								filterOption={(input, option) =>
									option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}>
								{programDetails.map((item: IPaymentType) => (
									<Option key={item.id} value={item.id}>
										{item.name}
									</Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item
							label="Payment For"
							name="payment_type"
							rules={[
								{
									required: true,
									message: "Please select the payment type!",
								},
							]}>
							<Select
								showSearch
								filterOption={(input, option) =>
									option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}>
								{paymentTypes.map((item: IPaymentType) => (
									<Option key={item.id} value={item.id}>
										{item.name}
									</Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item
							label="Amount (in LKR)"
							name="amount"
							rules={[
								{
									required: true,
									message: "Please input the amount to pay!",
									type: "number",
								},
							]}>
							<InputNumber
								style={{ width: "100%" }}
								formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
								parser={(value) => value?.replace(/\$\s?|(,*)/g, "") as valueType}></InputNumber>
						</Form.Item>

						<Form.Item>
							<Row justify="center">
								<Spin spinning={submitStatus === "onSubmit" ? true : false}>
									<Button type="primary" htmlType="submit" form="paymentForm" key="submit">
										Submit
									</Button>
								</Spin>
							</Row>
							{submitStatus === "error" ? (
								<Row justify="center" style={{ paddingTop: "16px" }}>
									<Alert message={errorInfo} type="error" showIcon />
								</Row>
							) : null}
						</Form.Item>
					</Form>
				</Col>
			</Row>
		</>
	);
};

export default PaymentForm;
{
	/* <form action="https://google.com" method="post">
<input type="text"></input>
<input type="text"></input>
<input type="text"></input>
<input type="text"></input>
<input type="submit" title="Submit"></input>
</form> */
}
