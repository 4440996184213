import { Button, Col, Divider, Row, Space } from "antd";
import Title from "antd/lib/typography/Title";
import { parseISO } from "date-fns";
import { format, utcToZonedTime } from "date-fns-tz";
import { parse } from "qs";
import React from "react";
import { useLocation } from "react-router-dom";

interface ReceiptProps {}

const Receipt: React.FC<ReceiptProps> = (props) => {
	const {
		decision,
		payment_name,
		program_name,
		auth_amount,
		auth_time,
		card_type_name,
		req_bill_to_address_city,
		req_bill_to_address_country,
		req_bill_to_address_line1,
		req_bill_to_address_state,
		req_bill_to_email,
		req_bill_to_forename,
		req_bill_to_phone,
		req_bill_to_surname,
		req_card_number,
		req_currency,
		req_reference_number,
	} = parse(useLocation().search, { ignoreQueryPrefix: true }) as any;

	if (decision === "ACCEPT") {
		return (
			<>
				<div className="receipt">
					<Row justify="space-between">
						<Col xs={16} sm={8}>
							<Title level={3}>Receipt</Title>
						</Col>
						<Col xs={16} sm={8}>
							<Row>
								<span>
									<strong>Date: </strong>
									{auth_time
										? format(utcToZonedTime(parseISO(auth_time), "Asia/Colombo"), " hh:mm aa, dd-MM-yyyy ")
										: ` No Date`}
								</span>
							</Row>
							<Row>
								<span>
									<strong>Order Number: </strong>
									{` ${req_reference_number}`}
								</span>
							</Row>
						</Col>
					</Row>
					<Divider></Divider>
					<Row>
						<span>{`${payment_name} for ${program_name}`}</span>
					</Row>
					<Divider></Divider>
					<Row>
						<Title level={5}>Billing Information</Title>
					</Row>
					<Row>
						<span style={{ whiteSpace: "pre-line" }}>{`${req_bill_to_forename} ${req_bill_to_surname}
${req_bill_to_address_line1}
${req_bill_to_address_city}${req_bill_to_address_state ? `\n${req_bill_to_address_state}` : ""}
${req_bill_to_address_country}
			
${req_bill_to_email}
${req_bill_to_phone}
			`}</span>
					</Row>
					<Divider></Divider>
					<Row justify="space-between">
						<Col xs={16} sm={8}>
							<Title level={5}>Payment Details</Title>
							<span>{card_type_name}</span>
							<br></br>
							<span>{req_card_number}</span>
							<br></br>
							<br></br>
						</Col>
						<Col xs={16} sm={8}>
							<Title level={5}>Order Total</Title>
							<span>
								<strong>Total Amount: </strong>
								{`${auth_amount} ${req_currency}`}
							</span>
						</Col>
					</Row>
				</div>
				<div className="receipt_btn-section">
					<Divider></Divider>
					<Row justify="center">
						<Space>
							<Button href="/">Home</Button>
							<Button onClick={() => window.print()} type="primary">
								Print Receipt
							</Button>
						</Space>
					</Row>
				</div>
			</>
		);
	} else if (decision === "REVIEW") {
		return (
			<>
				<Row>
					<Title level={4}>Authorization was declined</Title>
				</Row>
				<Row>Review payment details. </Row>
				<div className="receipt_btn-section">
					<Divider></Divider>
					<Row justify="center">
						<Space>
							<Button href="/">Home</Button>
						</Space>
					</Row>
				</div>
			</>
		);
	} else if (decision === "DECLINE") {
		return (
			<>
				<Row>
					<Title level={4}>Transaction was declined</Title>
				</Row>
				<Row>Review payment details. </Row>
				<div className="receipt_btn-section">
					<Divider></Divider>
					<Row justify="center">
						<Space>
							<Button href="/">Home</Button>
						</Space>
					</Row>
				</div>
			</>
		);
	} else if (decision === "ERROR") {
		return (
			<>
				<Row>
					<Title level={4}>Error occurred</Title>
				</Row>
				<Row>Check your internet connection or notify the bank. </Row>
				<div className="receipt_btn-section">
					<Divider></Divider>
					<Row justify="center">
						<Space>
							<Button href="/">Home</Button>
						</Space>
					</Row>
				</div>
			</>
		);
	} else if (decision === "CANCEL") {
		return (
			<>
				<Row>
					<Title level={4}>Transaction cancelled</Title>
				</Row>
				<Row>The customer cancelled the transaction or the customer did not accept the service fee conditions.</Row>
				<div className="receipt_btn-section">
					<Divider></Divider>
					<Row justify="center">
						<Space>
							<Button href="/">Home</Button>
						</Space>
					</Row>
				</div>
			</>
		);
	}
	return (
		<>
			<Row>
				<Title level={4}>Error occurred</Title>
			</Row>
			<Row>Check your internet connection or notify the administrator. </Row>
			<div className="receipt_btn-section">
				<Divider></Divider>
				<Row justify="center">
					<Space>
						<Button href="/">Home</Button>
					</Space>
				</Row>
			</div>
		</>
	);
};

export default Receipt;
