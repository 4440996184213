import React, { Component } from "react";
import { Route, Switch } from "react-router";
import PaymentConfirmation, { PaymentConfirmationProps } from "../payment/PaymentConfirmation";
import PaymentForm from "../payment/PaymentForm";
import Receipt from "../receipt/Receipt";
import NotFound from "./NotFound";
interface Props {}
export interface IPaymentSignedState {
	[key: string]: string | undefined;
	access_key: string | undefined;
	amount: string | undefined;
	currency: string | undefined;
	locale: string | undefined;
	profile_id: string | undefined;
	reference_number: string | undefined;
	signature: string | undefined;
	signed_date_time: string | undefined;
	signed_field_names: string | undefined;
	transaction_type: string | undefined;
	transaction_uuid: string | undefined;
	unsigned_field_names: string | undefined;
}
export interface IPaymentUnsignedState {
	bill_to_email: string | undefined;
	bill_to_forename: string | undefined;
	bill_to_surname: string | undefined;
	bill_to_phone: string | undefined;
	student_id: string | undefined;
	payment_type: string | undefined;
	program: string | undefined;
}
export interface IRoutesState {
	signedState: IPaymentSignedState;
	unsignedState: IPaymentUnsignedState;
	infoAdded: boolean;
}
export default class Routes extends Component<Props, IRoutesState> {
	constructor(props: Props) {
		super(props);
		this.state = {} as IRoutesState;
	}
	stateSetter = this.setState.bind(this);

	render() {
		return (
			<>
				<Switch>
					<Route exact path="/" render={(props) => <PaymentForm setState={this.stateSetter} />}></Route>
					<Route
						exact
						path="/confirm"
						render={
							this.state.infoAdded ? (props) => <PaymentConfirmation {...this.state} /> : () => <NotFound></NotFound>
						}></Route>
					<Route exact path="/receipt" component={Receipt}></Route>
					<Route component={NotFound}></Route>
				</Switch>
			</>
		);
	}
}
