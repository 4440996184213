import { Col, Row, Typography } from "antd";
import React from "react";

interface TitleSectionProps {
  title?: string;
  desc: string;
}

const TitleSection: React.FC<TitleSectionProps> = ({
  desc,
  title = "Payment Portal",
}) => {
  return (
    <>
      <Row justify="center" style={{ textAlign: "center" }}>
        <Col
          xs={22 + 2}
          sm={19 + 2}
          md={16 + 2}
          lg={13 + 2}
          xl={10 + 2}
          xxl={8 + 2}>
          <Typography.Title level={3}>{title}</Typography.Title>
          <Typography.Text>{desc}</Typography.Text>
        </Col>
      </Row>
    </>
  );
};

export default TitleSection;
