import { MenuOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd";
import { Header } from "antd/lib/layout/layout";
import React from "react";
import Logo from "./Logo.png";
const menu = (
  <Menu>
    <Menu.Item className="dropdown-container">
      <a
        className="navbar-link"
        target="_blank"
        rel="noopener noreferrer"
        href="https://ccbm.lk/cim-uk/">
        CIM UK
      </a>
    </Menu.Item>
    <Menu.Item className="dropdown-container">
      <a
        className="navbar-link"
        target="_blank"
        rel="noopener noreferrer"
        href="https://ccbm.lk/contact-us/">
        Contact Us
      </a>
    </Menu.Item>
  </Menu>
);

const HeaderComp = React.memo(function HeaderOutput() {
  return (
    <Header className="header">
      <a
        // target="_blank"
        rel="noopener noreferrer"
        href="/"
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}>
        <img className="header-logo" src={Logo}></img>
      </a>
      <Dropdown
        placement="bottomRight"
        overlay={menu}
        arrow
        trigger={["click"]}>
        <Button
          type="text"
          icon={<MenuOutlined />}
          size="large"
          style={{ fontFamily: "Inter" }}></Button>
      </Dropdown>
    </Header>
  );
});
export default HeaderComp;
