import React from "react";
import "./App.less";
import { Header } from "antd/lib/layout/layout";
import { Button, Dropdown, Menu, Layout, Row, Typography } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import PaymentForm from "./features/payment/PaymentForm";
import SectionWrapper from "./features/common/SectionWrapper";
import HeaderComp from "./features/common/HeaderComp";
import FooterComp from "./features/common/FooterComp";
import Routes from "./features/common/Routes";
const { Footer } = Layout;
function App() {
  return (
    <>
      <HeaderComp></HeaderComp>
      <div className="App">
        <div className="wrapper__main">
          <SectionWrapper>
            <Routes></Routes>
          </SectionWrapper>
        </div>
        <FooterComp></FooterComp>
      </div>
    </>
  );
}

export default App;
