import { IPaymentType } from "./payments.types"

const paymentTypes: Array<IPaymentType> = [
    { id: "0", name: 'Registration' },
    { id: "1", name: 'Turnitin Plagiarism Fee' },
    { id: "2", name: 'Module Fee' },
    { id: "3", name: 'Assesment Fee' },
    { id: "4", name: 'Late Assesment Fee' },
    { id: "5", name: 'Other' },
]
export default paymentTypes