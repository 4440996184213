import { Layout } from "antd";
import React, { Children, ReactChildren } from "react";

interface SectionWrapperProps {
  style?: React.CSSProperties;
}

const SectionWrapper: React.FC<SectionWrapperProps> = (props) => {
  return (
    <Layout className="wrapper__section" style={props.style}>
      {props.children}
    </Layout>
  );
};

export default SectionWrapper;
